.contacts {
    padding: 80px 0 80px;
  

@include media("<=810px") {
    
}
}

.contacts__inner  {
    display: flex;
    
        align-items: center;
        gap: 40px;
        justify-content: space-between;
      
    @include media("<=810px") {
        flex-direction: column;
        
    }
}



.contacts__form {
    width: 100%;
    max-width: 520px;
    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
}

.form {
    display: flex;
   flex-direction: column;
   align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 900px;
    padding-top: 40px;
    @include media("<=630px") {
        flex-direction: column;
    }
    
}

.form__input {
    width: 100%;
    
    input {
     
        width: 100%;
        font-size: 16px;
        color: #fff;
        padding: 24px;
        border-radius: 2px;
            border: 1px solid #F4E022;
            background: transparent;
        @include media("<=430px") {}
    }
    textarea {
        resize: none;
     
        width: 100%;
            font-size: 16px;
            color: #fff;
            color: #fff;
                padding: 24px;
                border-radius: 2px;
                border: 1px solid #F4E022;
                background: transparent;
    }
    
        
}

.contacts__email {
    line-height: 1.2;
    padding-top: 8px;
    font-family: "Urbanist", sans-serif;
    a {
        color: #161616;
        text-decoration: none;
        font-weight: 500;
    }
}

.contacts__image {
    max-width: 442px;
    width: 100%;
    img {
        width: 100%;
    }
}

.contacts__head-inner {
    display: flex;
    gap: 40px;
    padding: 20px 0 40px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
    }
}

.contacts__list {
    display: flex;
    flex-direction: column;
    gap: 18px;
    line-height: 1.3;
    font-size: 14px;
}

.list__title {
    font-weight: 800;
    font-size: 24px;
}

.contacts__content {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
   max-width: 520px;
    line-height: 1.4;
    @include media("<=650px") {
        
    }
}

.contacts__title {
    font-size: 32px;
    font-weight: 700;
    font-style: italic;
}

.contacts__text {
display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 4px;
    font-size: 16px;
    span {
        font-weight: 600;
    }
}

.form__button {
    text-decoration: none;
        border: none;
        width: 100%;
     
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 22px;
    
        text-decoration: none;
        text-align: center;
        padding: 16px;
        width: 100%;
        border-radius: 24px;
        border: 2px solid #FFF;
        background-color: #fff;
        transition: all ease-in-out 0.3s;
        position: relative;
        z-index: 25;
    
        &:hover {
            background-color: transparent;
            color: #fff;
        }
}

.form__title {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
}