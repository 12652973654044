.cards {
    padding: 60px 0;
}

.cards__inner {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding-top: 32px;
    @include media("<=810px") {
      flex-direction: column;
      align-items: center;
    }
}

.cards__item {
    flex: 0 1 32%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 0px 40px 40px;
        background: #252525;
        @include media("<=840px") {
            max-width: 400px;
        }
}

.cards__image {
    width: 100%;
    margin-bottom: 16px;
    img {
        width: 100%;
    }
}

.cards__title {
    text-transform: uppercase;
    display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 16px;
        font-weight: 600;
        padding: 0 16px;
}

.cards__text {
    padding: 16px 16px 24px;
}