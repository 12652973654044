.header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #DE1B4A;
  padding: 22px 0;
  @include media("<=1080px") {
    
  }
  @include media("<=840px") {
    padding: 20px 0;
  }
  @include media("<=430px") {
    
  }
 &-page {
  background: #262F24;
 }
}

.header__inner {
  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 8px;
  z-index: 30;
  @include media("<=1080px") {
  
      margin: 0 auto;
    }
  @include media("<=810px") {
    flex-wrap: wrap;
    align-items: center;
    
  }
}

.header__nav {
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @include media("<=1080px") {
    
  }

  @include media("<=810px") {
  display: none;
  }
}

.header__link {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #Fbfbfb;
  border-radius: 4px;
    border: 1px solid #FDFFE7;
    padding: 16px 22px;
  transition: all 0.3s ease;
  position: relative;

  @include media("<=1080px") {
    
  }
  @include media("<=430px") {
     font-size: 14px;
     font-weight: 500;
  }

  &:hover {
    color: #DE1B4A;
    background-color: #Fbfbfb;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #F7EDE3;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
  display: none;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.logo {
  display: block;
  color: #fbfbfb;
  font-size: 24px;
  font-weight: 700;
  z-index: 21;
  text-decoration: none;
  max-width: 253px;
  @include media("<=1440px") {
    
  }
    @include media("<=1080px") {
      
    }
  @include media("<=810px") {
   
    
  }
    @include media("<=430px") {
      
    }
  img {
    width: 100%;
    display: block;
  }
  
}

