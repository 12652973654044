.reviews {
  padding: 80px 0;
  overflow: hidden;
  @include media("<=550px") {}

 
}

.reviews__slider {
  margin-top: 50px;
  width: 100%;
   overflow: hidden;
    @include media("<=780px") {
    
    }
}

.reviews__item {

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 1.1;
  gap: 20px;

  min-height: 346px;
  margin: 0 10px;
  @include media("<=780px") {
  
  }
}

.reviews__text {
  line-height: 1.5;
  padding: 15px;
}

.reviews__title {
  font-weight: 600;
  font-size: 16px;
  gap: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews__image {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  font-weight: 700;
  font-size: 24px;
}