.about {
    padding: 60px 0 ;
    background-image: url(../images/a-bcg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include media("<=810px") {
            padding-top: 160px;
        }
    &-2 {
        padding-top: 80px;
        background-image: url(../images/about-2.png);
    }
        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
        }
}

.about__inner {
    width: 100%;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
 
@include media("<=810px") {
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
   
}

.about__content {
   max-width: 488px;
width: 100%;
    flex: 0 1 100%;
    line-height: 2;
    @include media("<=810px") {
        max-width: 600px;
    }
}

.about__title {
    font-size: 16px;
    text-transform: uppercase;
}

.about__text {
    
    font-size: 18px;
    padding: 20px 0 30px;
    text-align: center;
    line-height: 1.4;
    @include media("<=430px") {
        font-size: 16px;
    }
    p {
       
    }
}


.about__image {
    max-width: 488px;
    width: 100%;
    flex: 0 1 100%;
    @include media("<=810px") {
        max-width: 600px;
        width: 100%;
    }
    img {
        width: 100%;
        display: block;
    }
}

.about__column {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
}


