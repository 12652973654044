.info {
   padding: 80px 0;
background: rgba(90, 55, 195, 0.20);
}

.info__inner {
    padding-top: 40px;
    display: flex;
    justify-content: center;
   padding-bottom: 40px;

@include media("<=810px") {
   flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
}
@include media("<=500px") {
    flex-direction: column;
    align-items: center;
}
}

.info__item {
    display: flex;
    flex-direction: column;  
    align-items: center;
    text-align: center;
    flex: 0 1 25%;
    color: #fff;
    line-height: 1.3;
      padding: 24px;
        
                 @include media("<=500px") {
                   flex: 0 1 100%;
                 }
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
            border-right: 1px solid #F4E022;
        @include media("<=810px") {
                border-right: none;
            }
        }
                @include media("<=810px") {
                    flex: 0 1 46%;
                    border-bottom: 1px solid #F4E022;
                }
}

.info__image {
    width: 100%;
    img {
        width: 100%;
    }
}



.info__title {
    font-size: 20px;
    font-weight: 700;
    padding: 20px;
    text-transform: uppercase;
    @include media("<=1080px") {
font-size: 20px;
    }
    span {
        font-size: 48px;
    }
}

.info__text {
    font-size: 16px;
    font-weight: 400;
    
    @include media("<=810px") {
        
    }
}

.blog {
    padding: 80px 0;
}

.blog__inner {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    gap: 40px;
    padding-bottom: 50px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
    }
}

.blog__item {
    flex: 0 1 32%;
    display: flex;
    flex-direction: column;
    background: rgba(90, 55, 195, 0.20);
    @include media("<=810px") {
        flex: 0 1 100%;
        max-width: 500px;
    }
}

.blog__text {
    padding: 24px;
    span {
        display: block;
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: 700;
    }
}

.blog__image {
    width: 100%;

    img {
        width: 100%;
    }
}