.features {
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  
  @include media("<=1440px") {

  }
  &-bcg {
    background-image: url(../images/f-bcg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
        }
  }
}

.features__inner {
   position: relative;
   z-index: 3;
  display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    z-index: 3;
    padding-top: 50px;
    padding-bottom: 50px;
    row-gap: 40px;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
         
        }
                @include media("<=810px") {
                   flex-direction: column;
                   align-items: center;
                }
        @include media("<=500px") {
          
        }
        &-column {
          padding-top: 40px;
          flex-wrap: nowrap;
        }
}

.features__item {
  position: relative;
  display: flex;
  flex-direction: column;
 
  line-height: 1.3;
  gap: 15px;
  flex: 0 1 33%;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid #F4E022;
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=810px") {
     flex: 0 1 100%;

     width: 100%;
  }
  @include media("<=450px") {

  }
 @include media("<=360px") {
   

 }
  
 &:nth-of-type(1) , &:nth-of-type(2) , &:nth-of-type(4) , &:nth-of-type(5) {
  border-right: 1px solid #F4E022;
  @include media("<=810px") {
    border-right: none;
  }
 }
 &-big {
   flex: 0 1 48%;
   border-bottom: none;

   &:nth-of-type(2) {
     border: none;
   }
     @include media("<=810px") {
        border-bottom: 1px solid #F4E022;
     }
 }
 ul {
  padding-left: 15px;
  list-style: disc;
  line-height: 1.4;
 }
}

.features__content {
  position: relative;
  z-index: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  &-2 {
    align-items: flex-end;
  }
}


.features__title {
  text-transform: uppercase;

  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;

@include media("<=1440px") {
  
}
  @include media("<=1280px") {
   
  }

  @include media("<=450px") {
    font-size: 20px;
  }
    @include media("<=380px") {
      
    }
    p {
      font-size: 16px;
      font-weight: 400;
      padding-top: 12px;
      text-transform: none;
    }
}

.features__text {
    display: flex;
    gap: 20px;
      flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
 
@include media("<=1080px") {

  }
  @include media("<=810px") {
    font-size: 14px;

  }

  @include media("<=430px") {
    font-size: 12px;
  }
}


.features__list {
  font-size: 16px;
    line-height: 130%;
    list-style: disc;
    padding-left: 15px;
}

.features__image {
  width: 100%;
  position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  img {
    width: 100%;
    display: block;
  }
}

.features__flex {
  display: flex;
  gap: 15px;
  flex: 0 1 47%;
  align-items: center;
  
}

.features__icon {
  max-width: 60px;
  width: 100%;
  margin-left: auto;
}



